import React, { Component } from 'react';
import queryString from "query-string";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeslaIcon from '../../icons/TeslaIcon';
import RdsBackIcon from '../../icons/RdsBackIcon';
import RDSButton from '../../components/common/RDSButton';
import RDSRadioButton from '../../components/common/RDSRadioButton';
import StoreStateConstants from '../../constants/store/StoreStateConstants';
import GlobalAlertConstants from '../../constants/GlobalAlertConstants';
import * as orderActionCreator from '../../actions/orderActionCreator';
import * as globalAlertActionCreator from '../../actions/globalAlertActionCreator';
import * as fbEventActionCreator from '../../actions/fbEventActionCreator';
import * as TranslationSelectors from '../../selectors/TranslationSelectors';
import LocalStorageUtil from '../../utils/LocalStorageUtil';
import { generateRandomString } from '../../utils/RandomGeneratorUtils';
import PagePathConstants from '../../constants/router/PagePathConstants';
import * as FBUtil from '../../utils/FbUtil';
import moment from 'moment/moment';
import StripeCustomPayment from '../../components/common/StripeCustomPayment/index';
import classNames from 'classnames';
import { sendCustomEvent } from '../../apis/ConfigAPI';
import { BASE_URL } from '../../constants/api/ApiEndpointConstants';
import CookieUtil from '../../utils/CookieUtil';


const MAXIMUM_DAILY_PAYMENT = 3;
class OrderConfirmationPage extends Component {
    constructor(props, context) {
        super(props, context);
        let { translations } = props;
        translations = translations && translations['submission_page'];
        this.state = {
            selectedInsuranceType: StoreStateConstants.PAY_FEE,
            insurance_types: this.renderInsuranceTypes(translations),
            isPaymenting: false,

            clientSecret: '',
            stripePkCode: '',
            orderNo: '',
            orderId: '',
            paymentIntentId: '',
            subscriptionId: '',
            showSTCustomForm: false,
            cSiteUrl: '',
            customerInfo: null,

            orderInfo: null,
            paymentTextIndex: 0,
            paymentLoadingTextArr: ['支払いシステムに接続しています...']
        };
        this.routeToSpecPage = this.routeToSpecPage.bind(this);
        this.renderInsuranceTypes = this.renderInsuranceTypes.bind(this);
        this.prefillOrderParams = this.prefillOrderParams.bind(this);
        this.handleSelectPayType = this.handleSelectPayType.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.goBack = this.goBack.bind(this);
        this.togglePaymentStatue = this.togglePaymentStatue.bind(this);
        this.toggleStripeCustomerForm = this.toggleStripeCustomerForm.bind(this);
        this.handleRouteToSharePage = this.handleRouteToSharePage.bind(this);
    }
    progressRef = React.createRef();

    componentDidMount() {
        this.prefillOrderParams();
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.translations !== this.props.translations) {
            let { translations } = nextProps;
            translations = translations && translations['submission_page'];
            this.setState({
                insurance_types: this.renderInsuranceTypes(translations)
            })
        }
    }

    goBack() {
        const { history } = this.props;
        history && history.goBack();
    }

    handleSelectPayType(key) {
        this.setState({ selectedInsuranceType: key })
    }

    prefillOrderParams() {
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        this.setState({
            selectedInsuranceType: orderInfo.insuranceType ? orderInfo.insuranceType : this.state.selectedInsuranceType,
        });
    }

    renderInsuranceTypes(translations) {
        return [
            {
                key: StoreStateConstants.PAY_FEE,
                label: translations && translations.choice_pay_fee.replace('%s', 500),
            },
            // {
            //     key: StoreStateConstants.PAY_FAST_FEE,
            //     label: translations && translations.choice_pay_fast_fee.replace('%s', localStorage.getItem('payment_amount_fast')),
            // },
        ];
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.translations !== this.props.translations) {
            let { translations } = nextProps;
            translations = translations && translations['submission_page'];
            this.setState({
                insurance_types: this.renderInsuranceTypes(translations)
            })
        }
    }

    routeToSpecPage(url) {
        const { history, location } = this.props;
        history && history.push({ pathname: url, search: location.search });
    }

    handleSubmitForm() {
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const { firstName, lastName, email, phone, driveEmail, drivePhone, state, city, country, address, zipCode, vehicleModel, identityType, documentFront, insuranceType, snCode, documentBack, userSelfPictureOrVideo } = orderInfo;
        const { selectedInsuranceType } = this.state;

        const localPaymentIntentId = LocalStorageUtil.loadStripePaymentIntent();
        let data = {
            firstName,
            lastName,
            email,
            phone,
            driveEmail,
            drivePhone,
            state,
            city,
            country,
            address,
            zipCode,
            vehicleModel,
            identityType,
            documentFront: documentFront && documentFront.replace(BASE_URL, ''),
            userSelfPictureOrVideo: userSelfPictureOrVideo && userSelfPictureOrVideo.replace(BASE_URL, ''),
            insuranceType: selectedInsuranceType,
            id: '',
            orderNu: '',
            type: 10,
            curcrency: 2,
            payment_intent_id: localPaymentIntentId,
            ad: 'test',
        }
        const orderId = LocalStorageUtil.loadOrderId();
        if (orderId && orderId !== '' && orderId !== 'undefined') {
            data.id = orderId;
        }

        const orderNu = LocalStorageUtil.loadOrderNumber();
        if (orderNu && orderNu !== '' && orderNu !== 'undefined') {
            data.orderNu = orderNu;
        }
        if (country === 'US') {
            data['snCode'] = snCode;
        }
        if (data.identityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT) {
            data['documentBack'] = documentBack.replace(BASE_URL, '');
        }
        const { location, globalAlertActions, orderActions, fbEventActions } = this.props;
        const queryParams = queryString.parse(location.search);
        if (queryParams.ad && queryParams.ad !== '') {
            data['ad'] = queryParams.ad;
        }
        LocalStorageUtil.saveOrderDetail({ insuranceType: selectedInsuranceType, ad: queryParams.ad });
        if (firstName && firstName.trim() !== '' && lastName && lastName.trim() !== '') {
            // 限制24小时点击数最多3次
            const cachePaymentInfo = LocalStorageUtil.loadLastPaymentInfo();
            let paymentNum = 0;
            if (cachePaymentInfo && cachePaymentInfo.paymentNum) {
                paymentNum = parseInt(cachePaymentInfo.paymentNum);
            }
            paymentNum = paymentNum + 1;
            const now = moment();
            if (paymentNum > MAXIMUM_DAILY_PAYMENT && cachePaymentInfo.paymentTime && cachePaymentInfo.paymentTime !== undefined) {
                // 判断时间是否超过24小时
                const cacheTime = moment(cachePaymentInfo.paymentTime);
                const duration = moment.duration(now.diff(cacheTime)).as('hours');

                if (duration < 24) {
                    globalAlertActions && globalAlertActions.dispatchGlobalAlert('order_payment_times_limit', '現在のキャンペーンは熱すぎます。 明日もう一度お試しください。', GlobalAlertConstants.ERROR);
                    return;
                }
                paymentNum = 1;
            }
            const latestPaymentInfo = {
                paymentNum,
                paymentTime: now.valueOf()
            };
            LocalStorageUtil.saveLastPaymentInfo(latestPaymentInfo);

            this.togglePaymentStatue();


            setTimeout(() => {
                this.progressRef && this.progressRef.current && this.progressRef.current.classList.remove('active');
                this.progressRef && this.progressRef.current && this.progressRef.current.classList.add('active');
            }, 1000);

            // setTimeout(() => {
            //     this.setState({ paymentTextIndex: 1 });
            //     setTimeout(() => {
            //         this.setState({ paymentTextIndex: 2 });
            //     }, 4000);
            // }, 4000);

            let retryPaymentTime = 0;
            const submitOrder = () => {
                if (retryPaymentTime >= 3) {
                    LocalStorageUtil.clearStripePaymentIntent();
                    this.togglePaymentStatue();
                    globalAlertActions && globalAlertActions.dispatchGlobalAlert('order-failed-tip', '現在のキャンペーンは熱すぎます。 明日もう一度お試しください。', GlobalAlertConstants.ERROR);
                    return;
                }

                // 点击Checkout 按钮初始化支付流程
                const orderInfo = LocalStorageUtil.loadOrderDetail();
                const eventId = generateRandomString(10);
                const eventParams = {
                    content_category: 'Car test driver',
                    content_ids: 'test-driver',
                    contents: [{
                        id: 'test-driver',
                        title: orderInfo.vehicleModel && orderInfo.vehicleModel.trim() !== '' ? orderInfo.vehicleModel : 'Model 3',
                        quantity: 1,
                    }],
                    currency: 'JPY',
                    num_items: 1,
                    value: selectedInsuranceType === StoreStateConstants.PAY_FEE ? 500 : 1050
                };

                FBUtil.trackFBEvent('InitiateCheckout', eventParams, eventId);
                sendCustomEvent('InitiateCheckout');
                fbEventActions && fbEventActions.submitFBConversionEvent('InitiateCheckout', eventParams, null, eventId, null);


                retryPaymentTime += 1;

                orderActions.submitDriveOrder(data)
                    .then((orderDetail) => {
                        this.togglePaymentStatue();
                        if (orderDetail.clientSecret && orderDetail.api_publishable_key && orderDetail.payment_intent_id) {
                            LocalStorageUtil.saveStripePaymentIntent(orderDetail.payment_intent_id);
                            this.setState({
                                clientSecret: orderDetail.clientSecret,
                                stripePkCode: orderDetail.api_publishable_key,
                                orderNo: orderDetail.orderNo,
                                orderId: orderDetail.orderId,
                                paymentIntentId: orderDetail.payment_intent_id,
                                cSiteUrl: orderDetail.c_site_url,
                                subscriptionId: orderDetail.subscriptionId,
                                customerInfo: {
                                    name: data.firstName,
                                    email: data.email,
                                    phone: data.phone,
                                    address: {
                                        country: data.country,
                                        state: data.state,
                                        postal_code: data.zipCode,
                                        line1: data.address,
                                    }
                                },
                            }, this.toggleStripeCustomerForm);
                        }
                    })
                    .catch((err) => {
                        LocalStorageUtil.clearStripePaymentIntent();
                        LocalStorageUtil.clearOrderNumber();
                        LocalStorageUtil.clearOrderId();
                        data.id = '';
                        data.orderNu = '';
                        if (err && err.code === 1 && err.data && err.data.data === 'User locked') {
                            this.togglePaymentStatue();
                            globalAlertActions && globalAlertActions.dispatchGlobalAlert('order-repeat-tip', '現在のキャンペーンは熱すぎます。 明日もう一度お試しください。', GlobalAlertConstants.ERROR);
                            return;
                        } else if (err && err.code === 1 && err.data && err.data.data === 'User Repeated') {
                            this.togglePaymentStatue();
                            globalAlertActions && globalAlertActions.dispatchGlobalAlert('order-repeat-tip', 'ご注文ありがとうございます！ 5営業日以内にお客様の電話番号に注文状況をお送りいたします。', GlobalAlertConstants.INFO);
                            return;
                        } else {
                            submitOrder();
                        }
                    });
            }
            submitOrder();
        }
    }

    togglePaymentStatue() {
        this.setState({ isPaymenting: !this.state.isPaymenting }, () => {
            if (this.state.isPaymenting) {
                document.getElementById('hs-order-confirm-container').style.overflow = 'hidden';
            } else {
                document.getElementById('hs-order-confirm-container').style.overflow = 'auto';
            }
        });
    }

    toggleStripeCustomerForm() {
        this.setState({ showSTCustomForm: !this.state.showSTCustomForm }, () => {
            if (this.state.showSTCustomForm) {
                // 自动滚到页面底部
                const checkoutPageContainer = document.getElementById('hs-order-confirm-container');
                if (checkoutPageContainer) {
                    setTimeout(() => {
                        checkoutPageContainer.scrollTo(0, checkoutPageContainer.scrollHeight)
                    }, 1000);
                }
            }
        });
    }

    handleRouteToSharePage() {
        const { history, location } = this.props;
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const { email, phone } = orderInfo;
        sendCustomEvent('CancelTheCheckout');
        history && history.push({ pathname: PagePathConstants.SUBMISSION_CALLBACK, search: ((location.search && location.search !== '') ? `${location.search}&origin=submission&state=1&email=${email}&phone=${phone}` : `?origin=submission&state=1&email=${email}&phone=${phone}`) });
    }

    render() {
        let translations = this.props.translations && this.props.translations['submission_page'];
        const orderInfo = LocalStorageUtil.loadOrderDetail();
        const { firstName, lastName, vehicleModel, address, city, country, documentBack, documentFront, driveEmail, drivePhone, email, identityType, insuranceType, phone, snCode, state, userSelfPictureOrVideo, zipCode } = orderInfo;
        const { showSTCustomForm, selectedInsuranceType, isSubmitting, clientSecret, stripePkCode, orderId, orderNo, paymentIntentId, cSiteUrl, customerInfo, subscriptionId, isPaymenting, paymentLoadingTextArr, paymentTextIndex } = this.state;

        const disabledSubmitBtn = (!orderInfo || !firstName || firstName.trim() === '' || !lastName || lastName.trim() === '' || !email || email.trim() === '' || !phone || phone.trim() === '' || !address || address.trim() === '' || !state || state.trim() === '' || !zipCode || zipCode.trim() === '' || !country || country.trim() === '' || (country === 'US' && snCode.trim() === '') || isSubmitting)

        return (
            <div className='tw-w-full tw-h-full gotham-font tw-text-[#393c41] '>
                {isPaymenting && <div className='tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-flex tw-justify-center tw-items-center tw-z-[10000]' style={{ background: 'rgba(0,0,0,0.3)' }}>
                    <div className='tw-w-[90%] tw-px-[10px] tw-pt-[20px] tw-pb-[20px] tw-bg-white tw-rounded-[10px] hs-payment-dialog' style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                        <div className='tw-font-bold tw-flex tw-items-center tw-justify-center tw-rounded-[10px] '>
                            <div className='hs-loading-gif tw-mr-[10px]'></div>
                            {paymentLoadingTextArr.map((item, index) => {
                                return (<div key={item} className={classNames('tw-text-[18px]', { 'hs-payment-loading-text': index === paymentTextIndex }, { 'tw-hidden': index !== paymentTextIndex })}>{paymentLoadingTextArr[index]}</div>)
                            })}
                        </div>
                        <div ref={this.progressRef} className={classNames("hs-progress tw-mx-auto tw-mt-[15px]")}></div>
                    </div>
                </div>}
                <div className='tw-w-full tw-h-full phone:tw-h-full tw-pb-[200px] tw-overflow-scroll' id="hs-order-confirm-container">
                    <div className='tw-z-[100] tw-w-full tw-h-[80px] tw-flex tw-justify-between'>
                        <div className='tw-w-1/3 tw-h-full tw-flex tw-items-center tw-pl-[30px]'><RdsBackIcon color='#171a20' onClick={this.goBack} /></div>
                        <TeslaIcon size={150} color='#171a20' />
                        <div className='tw-w-1/3'></div>
                    </div>
                    <div className='tw-mt-[66px] tw-w-[800px] tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[36px]'>
                        <div className='tw-text-[30px] tw-leading-[60px] tw-font-[500] phone:tw-text-[24px]'>{translations && translations.order_confrim}</div>
                    </div>

                    <div className='tw-w-[800px] tw-mx-auto phone:tw-px-[20px] phone:tw-w-full phone:tw-mt-[36px]'>
                        <div className='tw-w-full tw-mb-[60px]'>
                            <div className='tw-px-[20px] tw-py-[20px] tw-mb-[25px] tw-flex tw-items-center tw-bg-[#f5f5fa]'>
                                <div className='tw-ml-[8px] tw-font-bold'>{translations && translations['brand_model']}</div>
                            </div>
                            <div className='tw-w-full phone:tw-w-full pad:tw-w-full phone:tw-text-[14px] '>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['brand_model']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{vehicleModel}</div></div>
                                </div>
                            </div>
                            <div className='tw-text-[18px] tw-pl-[30px] tw-py-[20px] tw-underline tw-text-light-blue' onClick={() => this.routeToSpecPage(PagePathConstants.VEHICLE_MODEL)}>{translations && translations['update_info']}</div>
                        </div>

                        <div className='tw-w-full tw-mb-[60px]'>
                            <div className='tw-px-[20px] tw-py-[20px] tw-mb-[25px] tw-flex tw-items-center tw-bg-[#f5f5fa]'>
                                <div className='tw-ml-[8px] tw-font-bold'>{translations && translations['order_info']}</div>
                            </div>
                            <div className='tw-w-full phone:tw-w-full pad:tw-w-full phone:tw-text-[14px] '>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_FN']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{firstName}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_LN']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{lastName}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_EA']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{email}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_DEA']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{driveEmail}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_PN']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{phone}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_DPN']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{drivePhone}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_State']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{state}</div></div>
                                </div>
                                {/* <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_City']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{city}</div></div>
                                </div> */}
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_Address']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{address}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_ZCode']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{zipCode}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_Country']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{country}</div></div>
                                </div>
                                {country && country === 'US' && <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['title_sn']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{snCode}</div></div>
                                </div>}
                            </div>

                            <div className='tw-text-[18px] tw-pl-[30px] tw-py-[20px] tw-underline tw-text-light-blue' onClick={() => this.routeToSpecPage(PagePathConstants.CUSTOM_INFO)}>{translations && translations['update_info']}</div>
                        </div>

                        {/* <div className='tw-w-full tw-mb-[60px]'>
                            <div className='tw-px-[20px] tw-py-[20px] tw-mb-[25px] tw-flex tw-items-center tw-bg-[#f5f5fa]'>
                                <div className='tw-ml-[8px] tw-font-bold'>{translations && translations['id_info']}</div>
                            </div>
                            <div className='tw-w-full phone:tw-w-full pad:tw-w-full phone:tw-text-[14px] '>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['id_type']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[60px] phone:tw-h-[100px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-all'>{identityType}</div></div>
                                </div>
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[320px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['front_of_doc']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[320px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'> <div className='tw-w-[500px] tw-h-[300px] pad:tw-w-[300px] pad:tw-h-[200px] phone:tw-w-[200px] phone:tw-h-[150px]' style={{ backgroundImage: `url(${documentFront})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div> </div>
                                </div>
                                {orderInfo && orderInfo.identityType !== StoreStateConstants.IDENTITY_TYPE_PASSPORT && <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[320px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['back_of_doc']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[320px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'> <div className='tw-w-[500px] tw-h-[300px]  pad:tw-w-[300px] pad:tw-h-[200px] phone:tw-w-[200px] phone:tw-h-[150px]' style={{ backgroundImage: `url(${documentBack})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div> </div>
                                </div>}
                                <div className='tw-flex tw-items-center '>
                                    <div className='tw-w-[30%] tw-h-[500px] tw-py-[10px] tw-px-[30px] phone:tw-px-[10px] tw-border tw-flex tw-items-center tw-font-bold'><div className='tw-line-clamp-2 phone:tw-line-clamp-3 tw-break-words'>{translations && translations['selfie_video']}</div></div>
                                    <div className='tw-w-[70%] tw-h-[500px] tw-py-[10px] tw-px-[30px] tw-border tw-flex tw-items-center'>
                                        <video id="video" width={400} height={300} src={userSelfPictureOrVideo} playsInline="true" webkit-playsinline="true" controls></video>
                                    </div>
                                </div>
                            </div>
                            <div className='tw-text-[18px] tw-pl-[30px] tw-py-[20px] tw-underline tw-text-light-blue' onClick={() => this.routeToSpecPage(PagePathConstants.DOCUMENT_INFO)}>{translations && translations['update_info']}</div>
                        </div> */}

                        <div className='tw-w-full tw-mt-[50px]'>
                            <div className='tw-text-[#5c5e62] tw-font-[500] tw-text-[28px] phone:tw-text-[22px]'>{translations && translations.title_ChooseAP}</div>
                            {this.state.insurance_types.map((item) => {
                                return <div className='tw-mt-[10px] tw-border-border-gray tw-border tw-px-[10px] tw-py-[10px] tw-mb-[10px] tw-rounded-[10px] tw-flex tw-items-center' key={item.key} onClick={() => this.handleSelectPayType(item.key)}>
                                    <RDSRadioButton
                                        name="grade-subject-selector"
                                        checked={item.key === selectedInsuranceType}
                                        id="grade-subject-radio"
                                    />
                                    <span className='tw-ml-[15px] tw-items-center tw-text-[14px] tw-font-[500]' dangerouslySetInnerHTML={{ __html: item.label }}></span>
                                </div>
                            })}
                        </div>

                        <div className='tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-text-white tw-mt-[30px]'>
                            {!showSTCustomForm && <RDSButton
                                color="primary"
                                className="tw-w-full tw-rounded-[6px]"
                                rectangle
                                onClick={this.handleSubmitForm}
                                disabled={disabledSubmitBtn}
                                loading={isPaymenting}>
                                <span>クレジットカードでのお支払い</span>
                            </RDSButton>}
                            {showSTCustomForm && <StripeCustomPayment clientSecret={clientSecret} stripePkCode={stripePkCode} orderId={orderId} orderNo={orderNo} paymentIntentId={paymentIntentId} subscriptionId={subscriptionId} cSiteUrl={cSiteUrl} customerInfo={customerInfo} {...this.props} />}
                            <RDSButton
                                className="tw-w-full tw-rounded-[6px] tw-mt-[20px] tw-text-brand-primary tw-border-[2px] tw-border-brand-primary !tw-px-[15px]"
                                rectangle
                                onClick={this.handleRouteToSharePage}
                                color="white">
                                <span>{translations && translations.pay_cancel}</span>
                            </RDSButton>
                        </div>
                    </div>

                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: TranslationSelectors.selectTranslations(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderActions: bindActionCreators(orderActionCreator, dispatch),
        globalAlertActions: bindActionCreators(globalAlertActionCreator, dispatch),
        fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmationPage);
